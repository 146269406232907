import Head from 'next/head'
import { useRouter } from 'next/router'
import { t } from '../utils/i18n'

interface PageHeadProps {
  description?: string
  title?: string
  noindex?: boolean
  keywords?: string
  thumbnail?: string
  ogImage?: string
}

const PageHead: React.FC<PageHeadProps> = ({
  title = t('WorshipOnline - Tutorials and Lessons For Instruments!'),
  description = t(
    'WorshipOnline guitar, keys, vocals, bass, and drum tutorials! Never show up unprepared on Sunday again in half the time! Lessons, chords, tabs, and more!'
  ),
  keywords = 'worship, guitar, bass, drums, keys, vocals, chords, tabs, lessons, tutorials, worshiponline',
  noindex = false,
  thumbnail = null,
  ogImage = null
}) => {
  const router = useRouter()
  const path = router.asPath === '/index' ? '' : router.asPath
  const canonicalUrl = `https://app.worshiponline.com${path}`
  return (
    <Head>
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width"
        key="viewport"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#ffffff" />
      <meta charSet="utf-8" />
      <title>{title}</title>

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="Worship Online" />

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta
        name="robots"
        content={
          noindex
            ? 'noindex'
            : 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        }
      />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="Worship Online" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/WorshipOnline"
      />
      <meta
        property="article:modified_time"
        content="2020-11-05T22:43:59+00:00"
      />
      {ogImage ? (
        <meta property="og:image" content={ogImage} />
      ) : (
        <meta
          property="og:image"
          content="https://app.worshiponline.com/preview.png"
        />
      )}

      <meta property="og:image:width" content="900" />
      <meta property="og:image:height" content="471" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@worshiponline" />
      <meta name="twitter:site" content="@worshiponline" />
      {thumbnail && <meta name="thumbnail" content={thumbnail} />}
    </Head>
  )
}

export default PageHead
